<template>
    <v-dialog :value="value" width="450" @click:outside="$emit('cancel')">
      <v-card>
        <v-card-title>
          Confirm Cancellation
        </v-card-title>
        <v-card-text>
          Cancel contract upload process for "{{ selectedContract.file_name }}"?
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="grey darken-1" text @click="$emit('closeCancel')">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="confirm">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: 'CancelDialog',
    mixins: [],
    props: {
      value: Boolean,
      selectedContract: Object
    },
    methods: {
      confirm() {
        this.$emit('cancel')
        this.$emit('closeCancel')
      }
    }
  }
  </script>